import React, { useState, useEffect } from 'react'
import GatsbyLink from '../GatsbyLink'
import { getScrollPos, isClient } from '../../utils/helpers'
import './ProductsNavigation.scss'

const NavChildren = props => {
  const { categories, parent, parentSlug, location } = props
  return (
    <ul>
      {categories.nodes &&
        categories.nodes.map(
          (category, index) =>
            parseInt(category.wordpress_parent) === parseInt(parent) && (
              <NavItem
                key={index}
                index={index}
                thisCategory={category}
                categories={categories}
                parentSlug={parentSlug}
                location={location}
              />
            )
        )}
    </ul>
  )
}

const NavItem = props => {
  const { thisCategory, categories, parentSlug, location } = props
  const { wordpress_id, slug, acf, link } = thisCategory

  if (!thisCategory) return null
  //const link = category.link
  //const category = props.category.acf
  const thisCategoryId = wordpress_id
  //const thisCategoryParentId = thisCategory.wordpress_parent
  const thisCategoryTitle = acf.categoryTitle
  const thisParentSlug = `${parentSlug}${slug}/`
  // If location.pathname includes this category path, make this item active
  const [active, setActive] = useState(
    location.pathname.includes(thisParentSlug)
  )
  // Test if current product category has at least 1 child assigned to it
  const hasChildren = categories.nodes
    ? categories.nodes.some(cat => {
        return cat.wordpress_parent === thisCategoryId
      })
    : false
  const toggleActiveClass = () => {
    setActive(!active)
  }

  return (
    <li className={`${hasChildren ? 'parent ' : ''}${active ? 'active' : ''}`}>
      {hasChildren && (
        <button className="chevron" type="button" onClick={toggleActiveClass} />
      )}
      <GatsbyLink to={link}>{thisCategoryTitle}</GatsbyLink>
      {hasChildren && (
        <NavChildren
          parent={thisCategoryId}
          categories={categories}
          parentSlug={thisParentSlug}
          location={location}
        />
      )}
    </li>
  )
}

export const ProductsNavigation = ({ productCategories, location }) => {
  // const { activeCategoryId = null, activeSupplierId = null } = props;
  const [productsNavigationOpen, setProductsNavigationOpen] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)
  const toggleProductsNavigation = () => {
    setProductsNavigationOpen(!productsNavigationOpen)
  }
  const onScroll = () => {
    setScrollPos(getScrollPos)
  }
  useEffect(() => {
    if (isClient) window.addEventListener('scroll', onScroll)
    return () => {
      if (isClient) window.removeEventListener('scroll', onScroll)
    }
  })
  return (
    <div
      className={`products-navigation-container ${
        productsNavigationOpen ? 'open ' : ''
      } ${scrollPos > 200 ? 'fixed' : ''}`}
    >
      <button
        className="products-navigation-toggle"
        type="button"
        onClick={toggleProductsNavigation}
      />
      <div className="products-navigation">
        <ul>
          {productCategories &&
            productCategories.nodes &&
            productCategories.nodes.map(
              (category, index) =>
                category.wordpress_parent === 0 && (
                  <NavItem
                    location={location}
                    key={index}
                    index={index}
                    thisCategory={category}
                    categories={productCategories}
                    parentSlug="/products/"
                  />
                )
            )}
        </ul>
      </div>
    </div>
  )
}
