import React from 'react'
import { graphql } from 'gatsby'
import Previewable from '../components/Previewable';
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { ProductsNavigation } from '../components/Products/ProductsNavigation';
import { ProductsTiles } from '../components/Products/ProductsTiles'
import { SuppliersList } from '../components/Products/SuppliersList'
import { Banner } from '../acf/Banner/Banner.jsx';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import { ProductSearch } from '../acf/ProductSearch/ProductSearch.jsx';
import { decodeEntities } from '../utils/htmlParse';
import './productsCategory.scss'

const ProductsCategory = (props) => {
  const {
    data,
    location,
    pageContext
  } = props;
  const {
    wordpressPost,
    productCategories,
    childCategories
  } = data;
  const categoryId = wordpressPost.wordpress_id;
  const {
    categoryTitle,
    image,
    blurb,
    documents = null,
    linkedSuppliers = null,
  } = wordpressPost.acf;
  const hasChildCategories = childCategories && childCategories.nodes && childCategories.nodes.length > 0;
  return (
    <Layout
      location={location}
      pageTitle={categoryTitle}
      className="product-category-template"
    >
      <SEO
        title={categoryTitle}
        desc=""
      />
      <ProductsNavigation productCategories={productCategories} location={location} />
      <Banner
        smallHeading="Products"
        heading={categoryTitle}
        image={{
          url: image
        }}
        location={location}
        pageContext={pageContext}
        pageTitle={categoryTitle}
      />
      <div className={`product-category-content ${blurb && blurb.replace(/(<\/p>)$/, '').split('</p>').length === 1 ? 'single-para' : ''}`}>
        <div className="wrapper">
          {blurb ? (
            <div className="blurb" dangerouslySetInnerHTML={{ __html: blurb }} />
          ) : (
            <div className="blurb">
              <p>
                Whether you need the best price for
                &nbsp;
                {categoryTitle}
                &nbsp;
                or a spare part (where you know what the exact part is) or you require assistance specifying the part you need, we can help.
              </p>
              <p>
                Once we have solved the problem we are able to provide you with a cost benefit analysis on the various
                &nbsp;
                {categoryTitle}
                &nbsp;
                solutions based upon the functional performance requirements and the project budget.
              </p>
              <p>
                The benefits of outsourcing to us are a reduction of downtime and we get it right the first time, every time, which means you won’t incur replacement costs or schedule delays.
              </p>
            </div>
          )}
        </div>
      </div>
      {documents && documents.length > 0 && (
        <div className="related-pdf-downloads">
          <div className="wrapper">
            <h2 className="related-pdfs-title">Related PDFs for Download</h2>
            {documents.map((doc, index) => (
              doc.filename && <a className="pdf-link" target="_blank" key={index} href={doc.filename}>{decodeEntities(doc.title)}</a>
            ))}
          </div>
        </div>
      )}
      {hasChildCategories && (
        <ProductsTiles
          activeCategoryId={categoryId}
          productCategories={productCategories}
          heading="Or choose from the following product subcategories:"
          slider
          wrap
          location={location}
        />
      )}
      <ProductSearch
        heading="We represent over 40 original equipment manufacturers."
        placeholder="Click here to search for 30,000+ products"
        fancyLabel="Search"
        includeFilters={false}
      />
      {!hasChildCategories && (
        <SuppliersList
          activeCategoryId={categoryId}
          title={categoryTitle}
          productCategories={productCategories}
          //products={products}
          linkedSuppliers={linkedSuppliers}
        />
      )}
      {!hasChildCategories && (
        <ProductsTiles
          productCategories={productCategories}
          heading="Or choose from our main product categories:"
          slider
          wrap
          location={location}
        />
      )}
      <FormBlock location={location} />
    </Layout>
  );
}

export default Previewable(ProductsCategory, 'product_category');

export const pageQuery = graphql`
  query CategoryFilterPageQuery($productCategoryId: Int) {
    site {
      siteMetadata {
        wordpressUrl
        siteTitle: title
      }
    }
    childCategories: allWordpressWpProductCategory(filter: { wordpress_parent: { eq: $productCategoryId } } ) {
      nodes {
        id
      }
    }
    wordpressPost: wordpressWpProductCategory( wordpress_id: { eq: $productCategoryId } ) {
      slug
      link
      slug
      wordpress_id
      wordpress_parent
      acf {
        thumb
        blurb
        categoryTitle
        image
        documents {
          title
          filename
        }
        linkedSuppliers
      }
    }
    productCategories: allWordpressWpProductCategory(sort: {order: ASC, fields: title}) {
      nodes {
        id
        link
        slug
        wordpress_id
        wordpress_parent
        acf {
          blurb
          categoryTitle
          image
          thumb
        }
      }
    }
  }
`
