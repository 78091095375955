import React, { useState } from 'react'
import { decodeEntities } from '../../utils/htmlParse'
import { Carousel } from '../Carousel'
import GatsbyLink from '../GatsbyLink'
import './ProductsNavigation.scss'
import './ProductsTiles.scss'
import LazyLoad from 'react-lazy-load'

const NavItem = props => {
  const { categories } = props
  const [dragStartXPos, setDragStartXPos] = useState()
  //const slug = [props.category.slug];
  const link = props.category.link
  const category = props.category.acf

  return (
    <GatsbyLink
      to={link}
      className="product-tile"
      decode={false}
      onMouseDown={e => {
        setDragStartXPos(e.pageX)
      }}
      onClick={e => {
        const xPosDiff = Math.abs(e.pageX - dragStartXPos)
        // If mouse dragged more than 10 pixels, prevent link click-through
        if (xPosDiff > 10) e.preventDefault()
      }}
    >
      <div className="product-tile-image">
        {category.thumb && (
          <img alt="product tile thumbnail" src={category.thumb} />
        )}
      </div>
      <span className="product-tile-title">
        {decodeEntities(category.categoryTitle)}
      </span>
    </GatsbyLink>
  )
}

const ProductTilesWrapper = ({ children }) => (
  <div className="products-tiles-container">
    <div className="wrapper">{children}</div>
  </div>
)

export const ProductsTiles = props => {
  const {
    productCategories,
    activeCategoryId = null,
    heading,
    slider = false,
    wrap = false,
  } = props
  const SliderWrapper = slider ? Carousel : React.Fragment
  const WrapperComponent = wrap ? ProductTilesWrapper : React.Fragment
  const sliderSettings = slider
    ? {
        buttonsDisabled: true,
        responsive: {
          0: {
            items: 1,
          },
          800: {
            items: 2,
          },
          1080: {
            items: 3,
          },
        },
      }
    : null

  const filterProductCategories =
    productCategories &&
    productCategories.nodes.filter(category => {
      return activeCategoryId
        ? category.wordpress_parent === activeCategoryId // get child categories of activeCategory
        : category.wordpress_parent === 0 // get top level product categories
    })

  if (filterProductCategories && filterProductCategories.length === 0)
    return null

  return (
    <WrapperComponent>
      {heading && (
        <h2 className="products-tiles-container-heading">
          {decodeEntities(heading)}
        </h2>
      )}
      <div className={`products-tiles${slider ? ' slider' : ''}`}>
        <SliderWrapper {...sliderSettings}>
          {filterProductCategories &&
            filterProductCategories.map((category, index) => (
              <NavItem
                key={index}
                category={category}
                categories={productCategories}
                activeCategoryId={activeCategoryId}
              />
            ))}
        </SliderWrapper>
      </div>
    </WrapperComponent>
  )
}
