import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { childCategories } from '../../utils/helpers'
import GatsbyLink from '../GatsbyLink';
import './ProductsNavigation.scss';
import './SuppliersList.scss';

const SupplierItem = ({ supplier }) => {
  return (
    <li className="suppliers-list-item">
      <GatsbyLink to={supplier.link}>{`${supplier.acf.supplierTitle}`}</GatsbyLink>
    </li>
  );
}

class ThisSupplierFilter extends Component {
  render() {
    const { data, linkedSuppliers } = this.props;
    const { suppliers, site } = data;
    const newSuppliers = linkedSuppliers && suppliers.nodes.filter(a => linkedSuppliers.includes(a.wordpress_id))

    // If there are no related suppliers,
    if (!newSuppliers || newSuppliers.length === 0) return null;


    return (
      <div className="product-category-suppliers">
        <div className="wrapper">
          <h2 className="product-category-suppliers-heading">Product suppliers:</h2>
          <ul className="suppliers-list">
            {newSuppliers.map((supplier, index) => (
              <SupplierItem
                key={index}
                supplier={supplier}
                suppliers={suppliers}
                wordpressUrl={site.siteMetadata.wordpressUrl}
              />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}


export const SuppliersList = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              paginate
              wordpressUrl
            }
          }
          suppliers: allWordpressWpProductSupplier{
            nodes {
              slug
              link
              wordpress_id
              acf {
                logo {
                  source_url
                }
                supplierCode
                supplierTitle
                wordpress_id
              }
              industries
            }
          }
        }
      `}
      render={data => <ThisSupplierFilter {...props} data={data} />}
    />
  );
}
